<template>
  <section :class="style['mobile-form-container']">
    <b-container :class="['pb-md-4', style['login-wrapper']]">
      <ChooseAccount
        :show="show"
        :social_acc="social_acc"
        @onClose="handleClose"
        @onSelect="selectAccount"
      />
      <div :class="[style['login-background'], 'd-none d-lg-block']">
        <img :src="loginBg">
      </div>
      <div class="mb-md-5 my-md-4 p-2 p-md-3">
        <router-link
          to="/"
          class="navbar-brand font-weight-bold text-center my-6 px-2 d-none d-lg-inline-block"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="register-logo"
          >
        </router-link>
        <b-row class="justify-content-lg-start mx-0">
          <b-col cols="12">
            <div class="register-wrapper-header text-left pl-4 pt-5 mb-5">
              <h1 class="font-weight-bold mb-4 text-left text-white">
                Login
              </h1>
            </div>
          </b-col>
        </b-row>
        <form @submit.stop.prevent="onSubmit">
          <b-row :class="['pb-5 mx-0 pl-4', style['login-form']]">
            <b-col
              sm="12"
              md="12"
              xl="8"
            >
              <b-row class="justify-content-start mx-0">
                <b-col
                  md="9"
                  class="pl-0"
                >
                  <b-form-group
                    id="email_label"
                    label-for="email"
                    class="bg-transparent"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      v-validate="{ required: true }"
                      :state="validateState('email')"
                      data-vv-as="Email or Username"
                      placeholder="Email address or Username"
                      name="email"
                      type="text"
                      class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                    />
                    <b-form-invalid-feedback id="input-1-live-email">
                      {{ veeErrors.first("email") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-start mx-0">
                <b-col
                  md="9"
                  class="pl-0"
                >
                  <b-form-group
                    id="password_label"
                    label-for="password"
                    class="mb-3 bg-transparent"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      v-validate="{ required: true }"
                      :state="validateState('password')"
                      aria-describedby="input-1-live-password"
                      data-vv-as="Password"
                      name="password"
                      type="password"
                      placeholder="Password (ex:Test@123)"
                      class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                    />
                    <b-form-invalid-feedback id="input-1-live-password">
                      {{ veeErrors.first("password") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-start mx-0">
                <b-col
                  md="9"
                  class="pl-0"
                >
                  <div class="text-center w-100 mb-3">
                    <b-overlay
                      :show="isLoading"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      :class="['d-inline-block w-100', style['login-spinner']]"
                    >
                      <b-button
                        ref="button"
                        :disabled="isLoading"
                        variant="golden"
                        :class="[
                          'px-5 w-100 align-items-center btn btn-golden px-4 mr-3 text-uppercase mt-5 text-white',
                          style['login-btn'],
                        ]"
                        pill
                        type="submit"
                      >
                        <span class="px-4">Login</span>
                      </b-button>
                    </b-overlay>
                    <div class="w-100 pt-3">
                      <span
                        class="text-white font-weight-normal"
                      >Forgot Password?</span>
                      <router-link
                        to="/forgotpassword"
                        class="text-white font-weight-bold"
                      >
                        Click here
                      </router-link>
                    </div>
                  </div>
                  <div
                    class="text-center w-100 d-flex flex-column justify-content-center"
                  >
                    <b-button
                      type="button"
                      variant="rounded-pill"
                      class="btn bg-transparent rounded-pill border text-white font-weight-bold d-flex align-items-center text-left mb-3 px-3 py-4"
                      @click.prevent="showChooseAccount('facebook')"
                    >
                      <span class="mr-2 text-white"><icon-facebook
                        width="24 "
                        height="24"
                      /></span>
                      <span class="flex-grow-1">Continue with Facebook</span>
                    </b-button>
                    <b-button
                      type="button"
                      variant="rounded-pill"
                      class="btn bg-transparent rounded-pill border text-white font-weight-bold d-flex align-items-center text-left mb-3 px-4 py-4"
                      @click.prevent="showChooseAccount('google')"
                    >
                      <span class="mr-2 text-white"><icon-instagram
                        width="24"
                        height="24"
                      /></span>
                      <span class="flex-grow-1">Continue with Google</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-container>
  </section>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { _sendResponse } from "../js/common-function";
import { APIUrlList } from "../js/api_url";
import moment from "moment-timezone";
import ChooseAccount from "../components/Common/ChooseAccount.vue";
import { initFbsdk } from "../config/facebook_oAuth";
import logo from "../assets/logo/logo-white-text.png";
import loginBg from "../assets/images/lines.png";
import style from "../styles/login.module.scss";
import IconFacebook from "../assets/svg-import/facebook.svg";
import IconInstagram from "../assets/svg-import/instagram.svg";

export default {
    components: {
        ChooseAccount,
        IconFacebook,
        IconInstagram
    },
    data() {
        return {
            logo,
            style,
            email: "",
            password: "",
            login_type: 1,
            isLoading: false,
            show: false,
            social_acc: "",
            account_type: "",
            loginBg
        };
    },
    mounted() {
        initFbsdk();
    },
    methods: {
        showChooseAccount(social_acc) {
            this.show = true;
            this.social_acc = social_acc;
        },
        selectAccount(account_type) {
            this.show = false;
            this.account_type = account_type;
            if (this.social_acc == "google") {
                this.loginWithGoogle();
            }
            if (this.social_acc == "facebook") {
                this.loginWithFacebook();
            }
        },
        handleClose() {
            this.show = false;
            this.social_acc = "";
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        onSubmit() {
            this.isLoading = true;
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }

                let formData = new FormData();
                formData.append("email", this.email);
                formData.append("password", this.password);
                formData.append("login_type", this.login_type);
                axios
                    .post(API_BASE_URL + APIUrlList.LOGIN, formData)
                    .then((response) => {
                        this.isLoading = false;
                        if (response.data.status === false) {
                            _sendResponse("error", response.data.message);
                            return false;
                        }
                        if (response.data.data.connected_via === 1) {
                            localStorage.setItem(
                                "user-image",
                                response.data.data.profile_pic_url
                            );
                        } else {
                            localStorage.setItem(
                                "user-image",
                                response.data.data.profile_pic_url
                            );
                        }
                        if (response.data.data.account_type === 2) {
                            localStorage.setItem("fabUsername", response.data.data.username);
                            localStorage.setItem("fabId", response.data.data.id);
                            if (response.data.fab_fees !== null) {
                                localStorage.setItem(
                                    "videoCall",
                                    response.data.data.fab_fees.fab_default_video_call_fee
                                );
                                localStorage.setItem(
                                    "liveStream",
                                    response.data.data.fab_fees.fab_default_live_streamig
                                );
                                localStorage.setItem(
                                    "postFees",
                                    response.data.data.fab_fees.fab_default_exclusive_post_fee
                                );
                            } else {
                                localStorage.setItem("videoCall", 0);
                                localStorage.setItem("liveStream", 0);
                                localStorage.setItem("postFees", 0);
                            }
                        }
                        localStorage.setItem("userId", response.data.data.id);
                        localStorage.setItem("user-token", response.data.data.token);
                        localStorage.setItem("username", response.data.data.username);
                        localStorage.setItem(
                            "fullname",
                            response.data.data.name + " " + response.data.data.surname
                        );
                        localStorage.setItem(
                            "account-type",
                            response.data.data.account_type
                        );
                        localStorage.setItem(
                            "totalProducts",
                            response.data.data.shoppingcart.length
                        );
                        localStorage.setItem(
                            "productsList",
                            JSON.stringify(response.data.data.shoppingcart)
                        );
                        localStorage.setItem(
                            "isProfileUpdated",
                            response.data.data.is_profile_updated
                        );
                        localStorage.setItem(
                            "connectedVia",
                            response.data.data.connected_via
                        );
                        localStorage.setItem('showStreams', JSON.stringify(true));
                        window.location.href = "/feed/" + response.data.data.username;
                    })
                    .catch((error) => {
                        // handle authentication and validation errors here
                        this.isLoading = false;
                        _sendResponse("error", error.response.data.errors);
                    });
            });
        },
        loginWithGoogle() {
            this.$gAuth
                .signIn()
                .then((GoogleUser) => {
                    // on success do something
                    var userInfo = {
                        provider: "google",
                        provider_id: GoogleUser.getBasicProfile().getId(),
                        fullname: GoogleUser.getBasicProfile().getName(),
                        name: GoogleUser.getBasicProfile().getGivenName(),
                        surname: GoogleUser.getBasicProfile().getFamilyName(),
                        email: GoogleUser.getBasicProfile().getEmail(),
                        profileImage: GoogleUser.getBasicProfile().getImageUrl(),
                    };

                    var timezone = moment.tz.guess();
                    let formData = new FormData();
                    formData.append("account_type", this.account_type);
                    formData.append("profile_pic", userInfo.profileImage);
                    formData.append("name", userInfo.name);
                    formData.append("surname", userInfo.surname);
                    formData.append("email", userInfo.email);
                    formData.append("provider", userInfo.provider);
                    formData.append("provider_id", userInfo.provider_id);
                    formData.append("timezone", timezone);
                    formData.append("login_type", 2);
                    formData.append("accept_terms", 1);
                    formData.append("agree_process_personal_data", 1);
                    formData.append("connected_via", 2);

                    axios
                        .post(API_BASE_URL + APIUrlList.SOCIALLOGIN, formData)
                        .then((response) => {
                            if (response.data.status === false) {
                                _sendResponse("error", response.data.message);
                                return;
                            }
                            this.isLoading = false;
                            if (response.data.data.connected_via === 1) {
                                localStorage.setItem(
                                    "user-image",
                                    response.data.data.profile_pic_src
                                );
                            } else {
                                localStorage.setItem(
                                    "user-image",
                                    response.data.data.profile_pic_src
                                );
                            }
                            if (response.data.data.account_type === 2) {
                                localStorage.setItem(
                                    "fabUsername",
                                    response.data.data.username
                                );
                                localStorage.setItem("fabId", response.data.data.id);
                                if (response.data.fab_fees !== null) {
                                    localStorage.setItem(
                                        "videoCall",
                                        response.data.data.fab_fees.fab_default_video_call_fee
                                    );
                                    localStorage.setItem(
                                        "liveStream",
                                        response.data.data.fab_fees.fab_default_live_streamig
                                    );
                                    localStorage.setItem(
                                        "postFees",
                                        response.data.data.fab_fees.fab_default_exclusive_post_fee
                                    );
                                } else {
                                    localStorage.setItem("videoCall", 0);
                                    localStorage.setItem("liveStream", 0);
                                    localStorage.setItem("postFees", 0);
                                }
                            }
                            localStorage.setItem("userId", response.data.data.id);
                            localStorage.setItem("user-token", response.data.data.token);
                            localStorage.setItem("username", response.data.data.username);
                            localStorage.setItem(
                                "fullname",
                                response.data.data.name + " " + response.data.data.surname
                            );
                            localStorage.setItem(
                                "account-type",
                                response.data.data.account_type
                            );
                            localStorage.setItem(
                                "totalProducts",
                                response.data.data.shoppingcart.length
                            );
                            localStorage.setItem(
                                "isProfileUpdated",
                                response.data.data.is_profile_updated
                            );
                            localStorage.setItem(
                                "productsList",
                                JSON.stringify(response.data.data.shoppingcart)
                            );
                            localStorage.setItem(
                                "connectedVia",
                                response.data.data.connected_via
                            );
                            localStorage.setItem('showStreams', JSON.stringify(true));
                            window.location.href = "/feed/" + response.data.data.username;
                            // window.location.href = "/";
                        })
                        .catch((error) => {
                            // handle authentication and validation errors here
                            _sendResponse("error", error);
                        })
                        .finally(() => {});
                })
                .catch((error) => {
                    _sendResponse(
                        "error",
                        "Unable to process try after sometime." + error
                    );
                });
        },
        loginWithFacebook() {
            window.FB.login(
                (response) => {
                    if (response && response.authResponse) {
                        window.FB.api(
                            `/${response.authResponse.userID}`,
                            { fields: "id,name,first_name,last_name,email,picture" },
                            (userResponse) => {
                                if (userResponse) {
                                    var userInfo = {
                                        provider: "facebook",
                                        provider_id: userResponse.id,
                                        fullname: userResponse.name,
                                        name: userResponse.first_name,
                                        surname: userResponse.last_name,
                                        email: userResponse.email,
                                        profileImage: userResponse.picture.data.url,
                                    };

                                    var timezone = moment.tz.guess();
                                    let formData = new FormData();
                                    formData.append("account_type", this.account_type);
                                    formData.append("profile_pic", userInfo.profileImage);
                                    formData.append("name", userInfo.name);
                                    formData.append("surname", userInfo.surname);
                                    formData.append("email", userInfo.email);
                                    formData.append("provider", userInfo.provider);
                                    formData.append("provider_id", userInfo.provider_id);
                                    formData.append("timezone", timezone);
                                    formData.append("login_type", 2);
                                    formData.append("accept_terms", 1);
                                    formData.append("agree_process_personal_data", 1);
                                    formData.append("connected_via", 2);

                                    axios
                                        .post(API_BASE_URL + APIUrlList.SOCIALLOGIN, formData)
                                        .then((response) => {
                                            if (response.data.status === false) {
                                                _sendResponse("error", response.data.message);
                                                return;
                                            }
                                            this.isLoading = false;
                                            if (response.data.data.connected_via === 1) {
                                                localStorage.setItem(
                                                    "user-image",
                                                    response.data.data.profile_pic_src
                                                );
                                            } else {
                                                localStorage.setItem(
                                                    "user-image",
                                                    response.data.data.profile_pic_src
                                                );
                                            }
                                            if (response.data.data.account_type === 2) {
                                                localStorage.setItem(
                                                    "fabUsername",
                                                    response.data.data.username
                                                );
                                                localStorage.setItem("fabId", response.data.data.id);
                                                if (response.data.fab_fees !== null) {
                                                    localStorage.setItem(
                                                        "videoCall",
                                                        response.data.data.fab_fees
                                                            .fab_default_video_call_fee
                                                    );
                                                    localStorage.setItem(
                                                        "liveStream",
                                                        response.data.data.fab_fees
                                                            .fab_default_live_streamig
                                                    );
                                                    localStorage.setItem(
                                                        "postFees",
                                                        response.data.data.fab_fees
                                                            .fab_default_exclusive_post_fee
                                                    );
                                                } else {
                                                    localStorage.setItem("videoCall", 0);
                                                    localStorage.setItem("liveStream", 0);
                                                    localStorage.setItem("postFees", 0);
                                                }
                                            }
                                            localStorage.setItem("userId", response.data.data.id);
                                            localStorage.setItem(
                                                "user-token",
                                                response.data.data.token
                                            );
                                            localStorage.setItem(
                                                "username",
                                                response.data.data.username
                                            );
                                            localStorage.setItem(
                                                "fullname",
                                                response.data.data.name +
                          " " +
                          response.data.data.surname
                                            );
                                            localStorage.setItem(
                                                "account-type",
                                                response.data.data.account_type
                                            );
                                            localStorage.setItem(
                                                "totalProducts",
                                                response.data.data.shoppingcart.length
                                            );
                                            localStorage.setItem(
                                                "productsList",
                                                JSON.stringify(response.data.data.shoppingcart)
                                            );
                                            localStorage.setItem(
                                                "isProfileUpdated",
                                                response.data.data.is_profile_updated
                                            );
                                            localStorage.setItem(
                                                "connectedVia",
                                                response.data.data.connected_via
                                            );
                                            localStorage.setItem('showStreams', JSON.stringify(true));
                                            window.location.href =
                        "/feed/" + response.data.data.username;
                                        })
                                        .catch((error) => {
                                            // handle authentication and validation errors here
                                            _sendResponse("error", error.response.data.errors);
                                        })
                                        .finally(() => {});
                                }
                            },
                            this.params
                        );
                    } else {
                        _sendResponse("error", "Unable to process try after sometime.");
                    }
                },
                { scope: "email" },
                this.params
            );
        },
    },
};
</script>
